import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./redux";
import {SnackbarProvider} from "notistack";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

if (process.env.REACT_APP_NETWORK_NAME === "mainnet-beta") {
    Sentry.init({
        dsn: "https://6c3272b3fd994ff28fb5570bac0aa61e@o1166510.ingest.sentry.io/6328409",
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <App/>
        </SnackbarProvider>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
