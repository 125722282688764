//Types
export const Types = {
    SETPOOLFLAG: '@setPoolFlag'
}

//Reducer

const INITIAL_STATE = {

};

export default function reducer(
    state = INITIAL_STATE,
    { type, payload }
) {
    switch (type) {
        case Types.SETPOOLFLAG:
            return { ...state, pool_flag: payload }
        default:
            return state;
    }
};

//ActionS
export const setPoolFlag = (pool_flag) => ({ type: Types.SETPOOLFLAG, payload: pool_flag })